<template>
    <div class="group-list-root">
        <Modal
            v-model="isShowEdit"
            :title="isEdit?'修改':'新增'">
            <div slot="footer">
                <Button type="text"  @click="isShowEdit=false">取消</Button>
                <Button type="primary" @click="handData">确定</Button>
            </div>
            <Form :model="data" :rules="rule" label-position="left" :label-width="100">
                <FormItem label="分组名称" prop="name">
                    <Input type="text" v-model="data.name" placeholder="分组名称">
                    </Input>
                </FormItem>
                <FormItem label="分组描述" prop="description">
                    <Input type="textarea" :rows="2" v-model="data.description" placeholder="分组描述">
                    </Input>
                </FormItem>
            </Form>
        </Modal>
        <div class="group-l-opetion-bar">
            <Input suffix="ios-search" placeholder="输入名称" class="group-list-search group-list-bar-item"
                @on-enter="loadData" @on-click="loadData" v-model="searchText" ></Input>
            
            <Button type="primary" @click="deleteGroup()" class="group-list-bar-item" v-if="$context.isLogin" :disabled="this.selectRows.length==0">删除</Button>
            <Button type="primary" @click="showEdit(false)" class="group-list-bar-item"  v-if="$context.isLogin">新增分组</Button>
        </div>
        <div class="group-list-table">
            <Table :key="$context.isLogin" :show-header="true" :columns="columns" :data="datas" @on-selection-change="selectionChange"></Table>
        </div>
        <div class="group-list-page">
            <Page :total="pages.total" :current="pages.current" :show-total="true" :page-size="pages.pageSize"
                @on-change="loadCurrentPage" show-elevator  />
        </div>
    </div>
</template>

<script>

var btnRender = function(that){

    return function(h, params){
        var btns = [];
        btns.push(h('i-button', {
            props: {
                type: 'info',
                size: 'small',
                shape:'circle',
                icon:'md-open' //打开
            },
            style:{
                "margin-left":"10px"
            },
            attrs: {
                title: '打开'
            },
            on:{
                click:function(event){
                    that.$router.push('/template/'+params.row.id);
                }
            }
        }));
        if(that.$context.isLogin){
            btns.push(h('i-button', {
                props: {
                    type: 'default',
                    size: 'small',
                    shape:'circle',
                    icon:'md-create' //修改
                },
                style:{
                    "margin-left":"10px",
                    "display":that.$context.isLogin?'inline-block':'none'
                },
                attrs: {
                    title: '修改'
                },
                on:{
                    click:function(event){
                        that.currentData = params.row;
                        that.data.name = that.currentData.name;
                        that.data.description = that.currentData.description;
                        that.showEdit(true);
                    }
                }
            }));
            btns.push(h('i-button', {
                props: {
                    type: 'default',
                    size: 'small',
                    shape:'circle',
                    icon:'md-trash' //删除
                },
                style:{
                    "margin-left":"10px",
                    "display":that.$context.isLogin?'inline-block':'none'
                },
                attrs: {
                    title: '删除'
                },
                on:{
                    click:function(event){
                        that.deleteGroup([params.row]);
                    }
                }
            }));
        }
        btns.push(h('i-button', {
            props: {
                type: 'primary',
                size: 'small',
                shape:'circle',
                icon:'md-download' //下载
            },
            style:{
                "margin-left":"10px"
            },
            attrs: {
                title: '下载'
            },
            on:{
                click:function(event){
                    that.$router.push('/groupDownload/'+params.row.id);
                }
            }
        }));
        return h('div',{
            style:{
                "text-align":"right"
            }
        }, btns);
    };
}

export default {
    data(){
        return {
            isShowEdit:false,
            isEdit:false,
            searchText:'',
            data:{
                name:'',
                description:''
            },
            selectRows:[],
            currentData:{},
            rule:{
                name: [
                    { required: true, message: '名称不能为空', trigger: 'blur' }
                ],
                description: [
                    { required: true, message: '描述不能为空', trigger: 'blur' }
                ]
            },
            columns:[{
                title: '分组名称',
                key: 'name',
                width: 250
            },
            {
                title: '分组描述',
                key: 'description'
            },
            {
                title: '操作',
                key: 'action',
                width: 250,
                render:btnRender(this)
            }],
            datas:[],
            pages:{
                current:1,
                total:0,
                pageSize:10
            }
        }
    },
    methods:{
        checkForm(){
            if(this.data.name==null||this.data.name==''){
                this.$Message.info('请填写分组名称');
                return false;
            }
            if(this.data.description==null||this.data.description==''){
                this.$Message.info('请填写分组描述');
                return false;
            }
            return true;
        },
        handData:function(){
            let result = this.checkForm();
            if(!result){
                return;
            }
            if(this.isEdit){
                this.updateGroup();
            }else{
                this.addGroup();
            }
            this.isShowEdit = false;
        },
        loadData:function(){
            var that = this;
            $.ajax({
                url: "/group/queryCount",
                async: true,
                type: 'POST',
                headers: {'Content-Type': 'application/json'},
                
                data: JSON.stringify({
                    search:this.searchText,
                    start:-1,
                    count:-1
                }),
                error:function(){
                    that.$Message.error('刷新失败');
                },
                success:function(result){
                    var nl = that.pages.current==1&&result!=0;
                    that.pages.current=1;
                    that.pages.total=result;
                    if(result==0){
                        that.datas = [];
                    }
                    if(nl){
                        that.loadCurrentPage();
                    }else{
                        that.$forceUpdate();
                    }
                },
            });
        },
        showEdit:function(isEdit){
            this.isShowEdit=true;
            this.isEdit=isEdit;
            if(!isEdit){
                this.data.name='';
                this.data.description='';
            }
        },
        deleteGroup:function(rows){
            var that = this;
            this.$Modal.confirm({
                title:"确认",
                content:"是否进行删除操作？",
                onOk:function(){
                    if(rows==null){
                        rows = that.selectRows;
                    }
                    
                    var ids = [];
                    for(let r in rows){
                        ids.push(rows[r].id);
                    }
                    $.ajax({
                        url: "/group/delete",
                        async: true,
                        type: 'POST',
                        data: JSON.stringify(ids),
                        headers: {'Content-Type': 'application/json'},
                        success:function(){
                            that.loadData();
                            that.$Message.info('删除成功');
                        },
                        error:function(){
                            that.$Message.error('删除失败');
                        }
                    });
                }
            })
            
        },
        updateGroup:function(){
            this.currentData.name=this.data.name;
            this.currentData.description = this.data.description;
            var that = this;
            $.ajax({
                url: "/group/update",
                async: false,
                type: 'POST',
                data: JSON.stringify(this.currentData),
                headers: {'Content-Type': 'application/json'},
                success:function(){
                    that.loadData();
                    that.$Message.info('更新成功');
                },
                error:function(){
                    that.$Message.error('新增失败');
                }
            });
        },
        addGroup:function(){
            var that = this;
            $.ajax({
                url: "/group/save",
                async: false,
                type: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: JSON.stringify(this.data),
                success:function(){
                    try{
                        that.loadData();
                    }catch(e){
                        console.log(e);
                    }
                    that.$Message.info('新增成功');
                },
                error:function(e){
                    console.log(e);
                    that.$Message.error('新增失败');
                }
            });
        },
        selectionChange:function(rows){
            this.selectRows = rows
        },
        loadCurrentPage:function(page){
            if(!page){
                page = 1;
            }
            var that = this;
            $.ajax({
                url: "/group/query",
                async: true,
                type: 'POST',
                headers: {'Content-Type': 'application/json'},
                dataType:"json",  
                data: JSON.stringify({
                    search:this.searchText,
                    start:(page-1)*this.pages.pageSize,
                    count:this.pages.pageSize
                }),
                success:function(result){
                    that.datas = result;
                    that.$forceUpdate();
                },
                error:function(){
                    that.$Message.error('加载失败');
                }
            });
        }

    },
    mounted(){
        let that = this;
        this.loadData();
        this.removeMsgListener = $msgCenter.addListener(this.$context.msgKey,function(){
            that.$forceUpdate();
        });
    },
    destory(){
        this.removeMsgListener();
    }
}
</script>

<style scoped>
.group-list-root{
    width: 1180px;
    margin: 0 auto;
}
.group-l-opetion-bar{
    margin: 0 auto;
    margin-top:30px;
    width: 1180px;
    text-align: right;
}

.group-list-search{
    width: auto;
}

.group-list-bar-item:last-child{
    margin-right: 0 !important;
}

.group-list-bar-item{
    margin-right: 15px;
}

.group-list-table{
    margin: 0 auto;
    margin-top:15px;
    width: 1180px;
    border-top: solid 1px rgb(200, 200, 200);
}

.group-list-page{
    margin: 0 auto;
    margin-top:30px;
    width: 1180px;
    text-align: right;
}

</style>
